let showSelectionM;
let Xstart;
let Ystart;
let canvasPageMarginTop = 0;
let canvasPageMarginLeft = 0;
let mouseDown = false;
let selectedObjects = [];

export const drawDivImage = (
  event,
  showSelection,
  XstartM,
  YstartM,
  setMultiSelect,
  multiSelect,
  arrayOfBoxesDrawn,
  setArrayOfBoxesDrawn,
  arrayOfBoxesDrawnRef,
  showMenu,
  scaleFactorImage
) => {
  mouseDown = true;

  showSelection = true;
  showSelectionM = true;

  // save the position where the mouse click started
  Xstart = event.pageX;
  Ystart = event.pageY;

  document.getElementById("rhs").getBoundingClientRect();
  let Xxstart =
    event.clientX -
    event.currentTarget.getBoundingClientRect().left +
    document.getElementById("rhs").scrollLeft;
  let Yystart =
    event.clientY -
    event.currentTarget.getBoundingClientRect().top +
    canvasPageMarginTop +
    document.getElementById("rhs").scrollTop;
  // let selection = multiSelect;
  setMultiSelect({ width: 0, height: 0, left: Xxstart, top: Yystart });

  // document.getElementById("rhs").onmousemove = mousedrag;

  document.addEventListener("onmouseup", (e) =>
    closeSelec(
      e,
      arrayOfBoxesDrawn,
      setArrayOfBoxesDrawn,
      arrayOfBoxesDrawnRef,
      multiSelect,
      setMultiSelect,
      showMenu,
      Xxstart,
      Yystart
    )
  );
  document.onmouseup = (e) =>
    closeSelec(
      e,
      arrayOfBoxesDrawn,
      setArrayOfBoxesDrawn,
      arrayOfBoxesDrawnRef,
      multiSelect,
      setMultiSelect,
      showMenu,
      Xxstart,
      Yystart
    );
};

export function mousedragImage(
  event,
  showSelection,
  XstartM,
  YstartM,

  setMultiSelect,
  setMultiSelectedComps,
  multiSelect
) {
  if (!mouseDown) return;

  // if we are making a selection lets keep updating our select box dimensions
  // let pos1 = Xstart - event.clientX;
  // let pos2 = Ystart - event.clientY;

  let leftValue =
    event.clientX - event.currentTarget.getBoundingClientRect().left;
  let Yvalue = event.clientY - event.currentTarget.getBoundingClientRect().top;
  let dragELement = document.getElementById("drawSelectionDiv");

  if (showSelectionM === true) {
    document.getElementById("rhs").onClick = null;

    if (event.pageX > Xstart) {
      // left to right
      dragELement.style.width = event.pageX - Xstart + "px";
    } else {
      // right to left

      dragELement.style.width = Xstart - event.pageX + "px";
      dragELement.style.left =
        event.clientX -
        event.currentTarget.getBoundingClientRect().left +
        document.getElementById("rhs").scrollLeft +
        "px";
      // Xstart - event.clientX;

      //event.clientX - event.currentTarget.getBoundingClientRect().left;
    }

    if (event.pageY > Ystart) {
      dragELement.style.height = event.pageY - Ystart + "px";
    } else {
      dragELement.style.height = Ystart - event.pageY + "px";
      dragELement.style.top =
        event.clientY -
        event.currentTarget.getBoundingClientRect().top +
        document.getElementById("rhs").scrollTop +
        "px";
      // Ystart - event.clientY;
      // event.clientY - event.currentTarget.getBoundingClientRect().top;
    }
  }

  let selectedObjs = [];

  selectedObjects = selectedObjs;
  setMultiSelectedComps(selectedObjs);

  setMultiSelect({
    ...multiSelect,
    pointerX: event.clientX,
    pointerY: event.clientY,
    timestamp: new Date().getTime(),
  });
  // selectedObjs = new Set([selectedObjs]);
}

export const closeSelec = (
  e,
  arrayOfBoxesDrawn,
  setArrayOfBoxesDrawn,
  arrayOfBoxesDrawnRef,
  multiSelect,
  setMultiSelect,
  showMenu,
  Xxstart,
  Yystart
) => {
  debugger;
  mouseDown = false;
  showSelectionM = false;
  document.getElementById("rhs").onmousemove = null;
  document.onmouseup = null;

  document.getElementById("drawSelectionDiv").click("Dom");

  let layoutDrawn = document.getElementById("drawSelectionDiv");
  let obj = {
    top: document.getElementById("drawSelectionDiv").style.top,
    left: document.getElementById("drawSelectionDiv").style.left,

    width: document.getElementById("drawSelectionDiv").style.width,

    height: document.getElementById("drawSelectionDiv").style.height,
    position: "absolute",
    border: "1px dashed  #f177e5",
    zIndex: arrayOfBoxesDrawn.length + 100,
    // zoom: "27.9%",
  };
  obj.right = parseFloat(obj.left) + parseFloat(obj.width) + "px";
  obj.bottom = parseFloat(obj.top) + parseFloat(obj.height) + "px";

  if (
    Number(obj.height.replace(/\D/g, "")) > 10 &&
    Number(obj.width.replace(/\D/g, "")) > 10
  ) {
    let tempArrayOfBoxesDrawn = JSON.parse(JSON.stringify(arrayOfBoxesDrawn));
    tempArrayOfBoxesDrawn.sort((a, b) => b.zIndex - a.zIndex);
    for (let i = 0; i < tempArrayOfBoxesDrawn?.length; i++) {
      if (
        parseFloat(obj.top) < parseFloat(tempArrayOfBoxesDrawn[i].top) &&
        parseFloat(obj.left) < parseFloat(tempArrayOfBoxesDrawn[i].left) &&
        parseFloat(obj.width) > parseFloat(tempArrayOfBoxesDrawn[i].width) &&
        parseFloat(obj.height) > parseFloat(tempArrayOfBoxesDrawn[i].height)
      ) {
        let newIndex = obj?.zIndex;
        obj.zIndex = tempArrayOfBoxesDrawn[i].zIndex;
        tempArrayOfBoxesDrawn[i].zIndex = newIndex;
      }
    }
    // tempArrayOfBoxesDrawn.sort((a, b) => parseFloat(b.top) - parseFloat(a.top));

    setArrayOfBoxesDrawn([...tempArrayOfBoxesDrawn, obj]);
    tempArrayOfBoxesDrawn.sort((a, b) => b.zIndex - a.zIndex);

    arrayOfBoxesDrawnRef.current = [...tempArrayOfBoxesDrawn, obj];
    // console.log("showmenucalled");
    showMenu(
      {
        x: parseFloat(obj.right) - 130 + "px",
        y: Yystart,
      },
      "onStopDraw"
    );
  } else {
    setMultiSelect({ ...multiSelect, width: "0px", height: "0px" });
  }
  document.getElementById("drawSelectionDiv").style.width = 0 + "px";
  document.getElementById("drawSelectionDiv").style.height = 0 + "px";
};
